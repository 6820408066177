import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Enerji Fitness Merkezi Teklifleri
			</title>
			<meta name={"description"} content={"Fitness Yolculuğunuzu Güçlendirmek"} />
			<meta property={"og:title"} content={"Enerji Fitness Merkezi Teklifleri"} />
			<meta property={"og:description"} content={"Fitness Yolculuğunuzu Güçlendirmek"} />
			<meta property={"og:image"} content={"https://etherealbreath.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://etherealbreath.com/img/2749777.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://etherealbreath.com/img/2749777.png"} />
			<meta name={"msapplication-TileImage"} content={"https://etherealbreath.com/img/2749777.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Hizmetlerimizi Keşfedin
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Fitness yolculuğunuzu desteklemek için kapsamlı bir teklif yelpazesi sunuyoruz. Energy Fitness Center'da, sağlık ve fitness hedeflerinize ulaşmanız için gereken araçlar, rehberlik ve olanaklarla sizi güçlendirmeye kararlıyız. Son teknoloji spor salonumuz ve deneyimli ekibimiz, her ziyaretinizden en iyi şekilde yararlanmanızı sağlamak için burada. Çeşitli hizmetlerimize dalın ve fitness deneyiminizi yükseltmenize nasıl yardımcı olabileceğimizi keşfedin.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://etherealbreath.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://etherealbreath.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://etherealbreath.com/img/6.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Grup Fitness Dersleri
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Energy Fitness Center'daki grup fitness dersleri formda kalmak için eğlenceli ve motive edici bir yol sunar. Çeşitli sınıf programımız, fitness seviyeniz veya ilgi alanlarınız ne olursa olsun herkes için bir şeyler içerir.
					<br/><br/>
Yüksek Yoğunluklu Aralıklı Antrenman (HIIT): Yüksek enerjili HIIT derslerimizle kalori yakın ve dayanıklılığınızı artırın.
					<br/><br/>
Yoga: Her seviyeye uygun çeşitli yoga stilleriyle esneklik, denge ve zihinsel berraklığı geliştirin.
					<br/><br/>
Pilates: Pilates: Dinamik Pilates seanslarımızla merkez bölgenizi güçlendirin ve kas tonunuzu artırın.
					<br/><br/>
Zumba Eğlenceli ve ilgi çekici Zumba derslerimizle zindeliğe giden yolda dans edin.
					<br/><br/>
Spin Dersleri: Kapalı alanda bisiklet sürmenin heyecanını yaşayın ve spin seanslarımızla kardiyovasküler sağlığınızı geliştirin.

				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Son Teknoloji Tesisler
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Tesisimiz, birinci sınıf ekipman ve olanaklarla optimum bir egzersiz ortamı sağlamak üzere tasarlanmıştır.
					<br/><br/>
Modern Ekipmanlar: Kardiyo makineleri, kuvvet antrenmanı ekipmanları ve serbest ağırlıklar dahil olmak üzere en son fitness teknolojisine erişin.
					<br/><br/>
Geniş Egzersiz Alanları: Egzersizlerinizi rahat ve güvenli bir şekilde gerçekleştirmek için geniş alanın keyfini çıkarın.
					<br/><br/>
Özel Antrenman Odaları: Daha gözlerden uzak bir ortamı tercih edenler için özel eğitim odalarımız mükemmel bir ortam sunmaktadır.
					<br/><br/>
Sauna: Sıkı bir egzersiz seansından sonra saunamızda rahatlayın ve gençleşin.
					<br/><br/>
Meyve Suyu Barı: Tesis bünyesindeki meyve suyu barımızda bulunan besleyici içecekler ve atıştırmalıklarla yakıt ikmali yapın.

				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Sağlık ve İyileşme Hizmetleri
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Energy Fitness Center, geleneksel fitness eğitiminin ötesine geçen hizmetler sunarak genel sağlığınıza kendini adamıştır.
					<br/><br/>
Beslenme Rehberliği: Beslenme uzmanlarımız, daha sağlıklı gıda seçimleri yapmanıza ve diyet hedeflerinize ulaşmanıza yardımcı olmak için kişiselleştirilmiş tavsiyeler sunar.
					<br/><br/>
Masaj Terapisi: Profesyonel masaj terapisi hizmetlerimizle kas gerginliğini azaltın ve iyileşmeyi destekleyin.
					<br/><br/>
Fizik Tedavi: Deneyimli fizyoterapistlerimizin yardımıyla sakatlıklardan kurtulun ve hareket kabiliyetinizi geliştirin.
					<br/><br/>
Farkındalık Programları: Zihinsel sağlığınızı geliştirmek için meditasyon ve stres azaltma derslerine katılın.

				</Text>
			</Box>
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://etherealbreath.com/img/7.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="74px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 50px 0px 50px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Image src="https://etherealbreath.com/img/8.jpg" display="block" width="100%" md-order="-1" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Bugün Bize Katılın!
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Daha sağlıklı, daha güçlü bir siz için bir sonraki adımı atın. Energy Fitness Center'a bugün katılın ve fitness hedeflerinize ulaşmanıza yardımcı olmak için sunduğumuz kapsamlı hizmetleri deneyimleyin. Gelin fitness yolculuğunuzu birlikte güçlendirelim!
					</Text>
					<Button
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						background="--color-primary"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});